import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom'
import {composeWithDevTools} from 'redux-devtools-extension';
import {Provider} from 'react-redux';
import rootReducer from './reducer/index'
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';


const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
  );

ReactDOM.render(
<BrowserRouter>

 <Provider store={store}>
 <App />
 </Provider>
 
 </BrowserRouter>
, document.getElementById('root')
);


serviceWorker.unregister();
