import React from "react";
import ReactTooltip from "react-tooltip";
import "./KeyFeagure.css";

export default class KeyFeagure extends React.Component {
  state = {
    data: [],
    Population: {},
    GDP_per_Capita: {},
    HDI_value: {},
    Income_Inequality: {},
    Gender_Inequality: {},
    Freedom_House: {}
  };

  componentWillReceiveProps(nextProps) {
    let Population,
      GDP_per_Capita,
      HDI_value,
      Income_Inequality,
      Gender_Inequality,
      Freedom_House;

    console.log("next props point section", nextProps.data[0].value);

    let keyData = nextProps.data;
    keyData.map((d, i) => {
      if (d.label == "Population") {
        Population = d;
      } else if (d.label == "GDP (PPP) per capita") {
        GDP_per_Capita = d;
      } else if (d.label == "HDI value") {
        HDI_value = d;
      } else if (d.label == "Income Inequality") {
        Income_Inequality = d;
      } else if (d.label == "Gender Inequality") {
        Gender_Inequality = d;
      } else if (d.label == "Freedom House") {
        Freedom_House = d;
      }
    });

    this.setState({
      data: nextProps.data,
      Population: Population,
      GDP_per_Capita: GDP_per_Capita,
      HDI_value: HDI_value,
      Income_Inequality: Income_Inequality,
      Gender_Inequality: Gender_Inequality,
      Freedom_House: Freedom_House
    });
  }

  render() {
    const {
      Population,
      GDP_per_Capita,
      HDI_value,
      Income_Inequality,
      Gender_Inequality,
      Freedom_House
    } = this.state;
    var a = parseInt(Population.value);
    var Population1 = conversion(a);
    var hdival = conversionHdi(HDI_value.value);
    function conversionHdi(b) {
      if (b >= 0 && b < 0.55) {
        return " (Low)";
      } else if (b >= 0.55 && b < 0.7) {
        return " (Medium)";
      } else if (b >= 0.7 && b < 0.85) {
        return " (High)";
      } else if (b >= 0.85 && b < 1) {
        return " (Very high)";
      }
    }

    function conversion(x) {
      if (x >= Math.pow(10, 3) && x < Math.pow(10, 6)) {
        return (x / Math.pow(10, 3)).toString().substring(0, 5) + " K";
      } else if (x >= Math.pow(10, 6) && x < Math.pow(10, 9)) {
        return (x / Math.pow(10, 6)).toString().substring(0, 5) + " M";
      } else if (x >= Math.pow(10, 9) && x < Math.pow(10, 12)) {
        return (x / Math.pow(10, 9)).toString().substring(0, 5) + " B";
      } else {
        return (x / Math.pow(10, 12)).toString().substring(0, 5) + " T";
      }
    }

    return (
      <section className=" pt-0 keyFeagureSection">
        <div className="row no-gutters ">
          <div className="col-lg-12">
            <div className="token_rtinfo ">
              <div className="row text-center no-gutters">
                <div className="col-lg-2 col-md-4 col-6 keyGrid">
                  <div className="keyfigureBox">
                    <div className="token_rt_value text-center ">
                      <p
                        ref="foo"
                        style={{ textAlign: "center" }}
                        data-tip={"" + "" + Population.source + ""}
                      >
                        {Population.label}
                      </p>
                      <h3> {Population1}</h3>

                      {/* <p style={{fontSize:"12px",color: "#a4a4a4"}}>{Population.source+ ""} </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-6 keyGrid">
                  <div className="keyfigureBox">
                    <div className="token_rt_value ">
                      <p
                        ref="foo"
                        style={{ textAlign: "center" }}
                        data-tip={"" + GDP_per_Capita.source + ""}
                      >
                        {GDP_per_Capita.label}
                      </p>
                      <h3>{GDP_per_Capita.value} </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-6 keyGrid">
                  <div className="keyfigureBox">
                    <div className="token_rt_value ">
                      <p
                        ref="foo"
                        style={{ textAlign: "center" }}
                        data-tip={"" + HDI_value.source + ""}
                      >
                        {HDI_value.label}
                        {hdival}
                      </p>

                      <h3> {HDI_value.value} </h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-6 keyGrid">
                  <div className="keyfigureBox">
                    <div className="token_rt_value ">
                      <p
                        ref="foo"
                        style={{ textAlign: "center" }}
                        data-tip={"" + Income_Inequality.source + ""}
                      >
                        {Income_Inequality.label}
                      </p>
                      <h3>{Income_Inequality.value}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-6 keyGrid">
                  <div className="keyfigureBox">
                    <div className="token_rt_value ">
                      <p
                        ref="foo"
                        style={{ textAlign: "center" }}
                        data-tip={"" + Gender_Inequality.source + ""}
                      >
                        {" "}
                        {Gender_Inequality.label}
                      </p>
                      <h3>{Gender_Inequality.value}</h3>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-6 keyGrid ">
                  <div className="keyfigureBox">
                    <div className="token_rt_value  text-center">
                      <p
                        ref="foo"
                        style={{ textAlign: "center" }}
                        data-tip={"" + Freedom_House.source + ""}
                      >
                        {" "}
                        {Freedom_House.label}
                      </p>
                      <h3>{Freedom_House.value}</h3>
                    </div>
                  </div>
                </div>
                <ul />
              </div>
            </div>
            <ReactTooltip />
          </div>
        </div>
      </section>
    );
  }
}
