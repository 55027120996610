import {KEY_FEAGURE} from './../type.js';

const Initialize = {
    key_feagure: []
}

export default function( state = Initialize, action ) {

    switch(action.type) {
        case KEY_FEAGURE:
            console.log("reducer", action);
            return Object.assign( {}, state, {key_feagure:action.data} )
        default:
            return state;
    }
}