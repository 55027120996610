import React from "react";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

// import components
import ReadyNessChart from "./readyness";

import "./ComparativeAnalysis.css";

const countryData = require("./default.json");
const countryData2008 = require("./SC2008.json");
const countryData2015 = require("./SC2015.json");
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSortDown} size="lg" />
    </components.DropdownIndicator>
  );
};
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #002F81",
    borderRadius: "0"
  }),
  option: (provided, state) => ({
    ...provided
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    height: "100%",
    backgroundColor: "#002F81"
  })
};
const indicatorSeparatorStyle = {
  backgroundColor: "#fff"
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

class ComparativeAnalysis extends React.Component {
  constructor(nextProps) {
    super(nextProps);

    this.state = {
      stateCountryData: {},
      StateCountriesData: {
        variables: countryData.variables,
        sets: countryData.sets
      },
      data: {},
      countriesDropdown: [{ value: "", label: "" }],
      countriesDropdownError: "",
      yearDropdown: [{ value: "2005-08", label: "2004-08" }]
    };

    this.handleCountryDropdown = this.handleCountryDropdown.bind(this);
    this.handleYearDropdown = this.handleYearDropdown.bind(this);
    this.changeData = this.changeData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        ...this.state,
        data: nextProps.data
      });
    }
  }

  componentWillMount() {
    let countryName = this.props.countryName;
    let countriesDropdown = [{ value: countryName, label: countryName }];

    var length = countryData2008.sets.length;

    for (var i = 0; i <= length; i++) {
      if (countryData.sets[i].key === countryName) {
        let initialNeedsData = countryData.sets[i];
        // console.log("endtry", initialNeedsData)
        this.setState({
          StateCountriesData: {
            ...this.state.StateCountriesData,
            sets: [initialNeedsData]
          },
          countriesDropdown: [{ value: countryName, label: countryName }]
        });
        break;
      }
    }
  }

  handleCountryDropdown(value) {
    let yearDropdown = this.state.yearDropdown;
    let countryDropdown = value;
    console.log('value of dropdown', value);
    this.changeData(yearDropdown, countryDropdown);
  }

  // Year Dropdown
  handleYearDropdown(value) {
    let yearDropdown = [value];
    let countryDropdown = this.state.countriesDropdown;

    this.changeData(yearDropdown, countryDropdown);
  }

  // function that changes data
  changeData(yearDropdownValue, countryDropdownValue) {
    // console.log("year dropdown", yearDropdownValue);
    // console.log("country dropdown", countryDropdownValue);

    let currentCountryState;

    if (yearDropdownValue[0].value === "2005-08") {
      currentCountryState = countryData2008;
    } else {
      currentCountryState = countryData2015;
    }

    let newCountryState = { variables: [], sets: [] };
    let newCountryDropdownValue;

    if (countryDropdownValue.length < 5) {
      newCountryDropdownValue = countryDropdownValue;

      let tempArrayState = [];
      console.log('currentCountryState.sets', currentCountryState.sets);
      newCountryDropdownValue.map(country => {
        let checkCountry = currentCountryState.sets.find(
          x => x.key === country.value
        );
        if (checkCountry !== undefined) {
          tempArrayState.push(checkCountry);
        }
      });
      newCountryState.sets = tempArrayState;

      newCountryState.variables = currentCountryState.variables;

      this.setState({
        ...this.state,
        StateCountriesData: {
          variables: newCountryState.variables,
          sets: newCountryState.sets
        },
        countriesDropdown: newCountryDropdownValue,
        yearDropdown: yearDropdownValue
      });
    } else {
      this.setState({
        ...this.state,
        countriesDropdownError: "You can select maximum of 4 countries",
        yearDropdown: yearDropdownValue
      });
    }
  }

  render() {
    const {
      data,
      countriesDropdown,
      countriesDropdownError,
      StateCountriesData,
      yearDropdown
    } = this.state;
    var mArrow = function arrowRenderer() {
      return <span>+++++++++++++</span>;
    };
    const countryOptions = [
      { value: "Afghanistan", label: "Afghanistan" },
      { value: "Bangladesh", label: "Bangladesh" },
      { value: "Bhutan", label: "Bhutan" },
      { value: "Cambodia", label: "Cambodia" },
      { value: "China", label: "China" },
      { value: "Hong Kong", label: "Hong Kong" },
      { value: "India", label: "India" },
      { value: "Indonesia", label: "Indonesia" },
      { value: "Japan", label: "Japan" },
      { value: "Lao PDR", label: "Lao PDR" },
      { value: "Malaysia", label: "Malaysia" },
      { value: "Myanmar", label: "Myanmar" },
      { value: "Mongolia", label: "Mongolia" },
      { value: "Nepal", label: "Nepal" },
      { value: "Pakistan", label: "Pakistan" },
      { value: "Philippines", label: "Philippines" },
      { value: "Sri Lanka", label: "Sri Lanka" },
      { value: "Singapore", label: "Singapore" },
      { value: "South Korea", label: "South Korea" },
      { value: "Taiwan", label: "Taiwan" },
      { value: "Thailand", label: "Thailand" },
      { value: "Vietnam", label: "Vietnam" }
    ];

    const yearOptions = [
      { value: "2005-08", label: "2004-08" },
      { value: "2009-2015", label: "2009-2015" }
    ];

    return (
      <div>
        <section id="spider" className="">
          <div className="row align-items-center no-gutters">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="limitedCountries">
                <h6 style={{ fontWeight: "bold" }}>
                  Compare up to four countries
                </h6>
              </div>
              <div className="text-center" style={{ marginLeft: "-15px" }}>
                <div className="row">
                  <div className="col-sm-5">
                    <Select
                      arrowRenderer={mArrow}
                      defaultValue={[countryOptions[0]]}
                      isMulti
                      name="Country"
                      styles={customStyles}
                      options={countryOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      components={{ DropdownIndicator, IndicatorSeparator }}
                      value={countriesDropdown}
                      onChange={value => this.handleCountryDropdown(value)}
                    />

                    <br />

                    {countriesDropdownError}
                  </div>

                  <div
                    className="col-sm-5"
                    style={{ backgroundColor: "white" }}
                  >
                    <Select
                      options={yearOptions}
                      name="Sentiments"
                      components={{ DropdownIndicator, IndicatorSeparator }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder={"Year"}
                      value={yearDropdown}
                      styles={customStyles}
                      onChange={value => this.handleYearDropdown(value)}
                    />
                  </div>
                </div>
                <div className="row" style={{ marginTop: "15px" }}>
                  <div className="col-sm-12">
                    <div className="row no-gutters block">
                      {countriesDropdown.map((d, i) => {
                        let color;
                        if (i === 0) {
                          color = "#3582B7";
                        } else if (i === 1) {
                          color = "#F08B39";
                        } else if (i === 2) {
                          color = "#55AD55";
                        } else {
                          color = "#D03938";
                        }

                        return (
                          <div
                            className="col-sm-3"
                            style={{
                              display: "flex",
                              paddingRight: "0px",
                              width: "auto"
                            }}
                          >
                            <div
                              style={{
                                background: `${color}`,

                                display: "inline-block",

                                marginRight: "8px",

                                height: "20px",
                                width: "20px"
                              }}
                            ></div>
                            <p className="fourCountries">{d.value}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-sm-12 col-md-8 radar"
                    style={{ padding: "50px" }}
                  >
                    <ReadyNessChart data={StateCountriesData} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="title_dark text_md_center">
                <h2 className=" h2Bold" style={{ textAlign: "left" }}>
                  Overall Index Of Social Cohesion
                </h2>
                <p style={{ textAlign: "-webkit-left" }}>
                  {data.SpiderChartData}{" "}
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ComparativeAnalysis;
