import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Route } from "react-router-dom";
import CountryTemplate from "./component/Pages/CountryTemplate";
import AsiaMap from "./component/AsiaMap/ComponentWrapper";
import SunburstChart from "./component/Sunburst/Sunburst123";

class App extends Component {
  render() {
    return (
      <div>
        <Route exact path="/India" component={CountryTemplate} />
        <Route exact path="/Bangladesh" component={CountryTemplate} />
        <Route exact path="/Afghanistan" component={CountryTemplate} />
        <Route exact path="/Cambodia" component={CountryTemplate} />
        <Route exact path="/Bhutan" component={CountryTemplate} />
        <Route exact path="/China" component={CountryTemplate} />
        <Route exact path="/Japan" component={CountryTemplate} />
        <Route exact path="/Hong Kong" component={CountryTemplate} />
        <Route exact path="/Indonesia" component={CountryTemplate} />
        <Route exact path="/Laos" component={CountryTemplate} />
        <Route exact path="/Malaysia" component={CountryTemplate} />
        <Route exact path="/Mongolia" component={CountryTemplate} />
        <Route exact path="/Myanmar" component={CountryTemplate} />
        <Route exact path="/Nepal" component={CountryTemplate} />
        <Route exact path="/Pakistan" component={CountryTemplate} />
        <Route exact path="/Philippines" component={CountryTemplate} />
        <Route exact path="/Singapore" component={CountryTemplate} />
        <Route exact path="/South Korea" component={CountryTemplate} />
        <Route exact path="/Sri Lanka" component={CountryTemplate} />
        <Route exact path="/Thailand" component={CountryTemplate} />
        <Route exact path="/Taiwan" component={CountryTemplate} />
        <Route exact path="/Vietnam" component={CountryTemplate} />
        <Route exact path="/sunburstchart" component={SunburstChart} />
        <Route exact path="/asiamap" component={AsiaMap} />
      </div>
    );
  }
}

export default App;
