import React from "react";
import "./countryHeader.css";

export default class CountryHeaderSection extends React.Component {
  state = {
    data: {}
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        ...this.state,
        data: nextProps.data
      });
    }
  }

  render() {
    const { data } = this.state;

    return (
      <section
        id="home_section"
        style={{
          backgroundImage: `linear-gradient(to right, rgba(0,0,0,1), rgba(255,0,0,0)),url(${data.countryImage})`
        }}
        className="section_banner"
      >
        <div className="row responsive-row">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="banner_text">
              <h1 className="text-white">{data.countryName}</h1>
              <p className="text-white">{data.countryShortDesc}</p>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div
            className="col-lg-6 col-md-6 col-sm-6"
            style={{ height: "10vh" }}
          >
            <div className="banner_inner res_md_mb_50 res_xs_mb_30">
              <div className="tk_countdown transparent_bg tk_border_white rounded-0 text-center ">
                <div className="banner_text tk_counter_inner banner_text">
                  <div
                    className="tk_countdown_time p-0 transparent_bg box_shadow_none "
                    data-time="2019/02/06 00:00:00"
                  ></div>
                  <div className="progress animation">
                    <div
                      className="progress-bar progress-bar-striped gradient"
                      role="progressbar"
                      aria-valuenow="46"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{
                        width: 17 + ((data.overAllCohesion + 1) * 100) / 2 + "%"
                      }}
                    >
                      {data.overAllCohesion}
                    </div>
                    <span
                      className="progress_label bg-white"
                      style={{ left: "2%" }}
                    >
                      {" "}
                      <strong> -1 </strong>
                    </span>
                    <span
                      className="progress_label bg-white "
                      style={{ left: "50%" }}
                    >
                      {" "}
                      <strong> 0 </strong>
                    </span>
                    <span
                      className="progress_label bg-white progressbar"
                      style={{
                        left: ((data.overAllCohesion + 1) * 100) / 2 + "%"
                      }}
                    >
                      {" "}
                      <strong> </strong>
                    </span>
                    <span
                      className="progress_label bg-white"
                      style={{ left: "98%" }}
                    >
                      {" "}
                      <strong> 1 </strong>
                    </span>
                    <span className="progress_min_val">
                      Overall index of social cohesion
                    </span>
                    <span className="progress_max_val">2009 - 2015</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
