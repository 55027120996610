import React from "react";

import { withRouter } from "react-router";

import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
// import { library } from "@fortawesome/fontawesome-svg-core";
import "./navbar.css";
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #002F81",
    borderRadius: "0",
    width: "100%",
    margin: "10px 0",
    boxSizing: "border-box",
    boxShadow: "0px 12px 14px rgba(0, 0, 0, 0.182235)"
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    padding: 20
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    backgroundColor: "#002F81"
  }),
  IndicatorSeparator: () => ({})
};

const countryOptions = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "China", label: "China" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Japan", label: "Japan" },
  { value: "Laos", label: "Laos PDR" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Nepal", label: "Nepal" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Philippines", label: "Philippines" },
  { value: "Singapore", label: "Singapore" },
  { value: "South Korea", label: "South Korea" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Thailand", label: "Thailand" },
  { value: "Vietnam", label: "Vietnam" }
];

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSortDown} size="lg" />
    </components.DropdownIndicator>
  );
};

const indicatorSeparatorStyle = {
  alignSelf: "stretch",
  backgroundColor: "#fff",
  marginBottom: 8,
  marginTop: 8,
  width: 1
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

class Menu extends React.Component {
  handleCountryDropdown(value) {
    // console.log("redirect to ", value);
    if (value.value) {
      return;
    }
  }
  render() {
    return (
      <header className="header_wrap">
        <nav className="navbar navbar-expand-lg">
          <div className="offset-md-9 col-md-3 col-sm-12 headerSelect">
            <Select
              placeholder={"Choose Country"}
              name="chooseCountry"
              components={{ DropdownIndicator, IndicatorSeparator }}
              styles={customStyles}
              options={countryOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={value => this.props.history.push("/" + value.value)}
            />
          </div>
        </nav>
      </header>
    );
  }
}

export default withRouter(Menu);
