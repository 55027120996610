import React from "react";
import Radar from "react-d3-radar";

const ReadyNessChart = (props, change) => {
  // var mySVG = document.getElementById("svg");
  // mySVG.setAttribute("viewBox", "0 0 100 100");
  console.log('props data', props.data.sets)
  return (
    <Radar
      width={400}
      height={400}
      padding={20}
      domainMax={6}
      highlighted={null}
      onHover={point => {
        if (point) {
        } else {
          console.log("not over anything");
        }
      }}
      data={{
        variables: props.data.variables,
        sets: props.data.sets
      }}
    />
  );
};

export default ReadyNessChart;
