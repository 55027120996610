import React, { Component } from "react";
import Scatter from "./ScatterPlot";
import "./ComponentWrapper.css";
import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import data from "./Data/explanationData.json";

const options = [
  {
    value: "GDP per capita PPP",
    label: "Overall index of social cohesion relative to gross domestic product"
  },
  {
    value: "Net-Income inequality Gini",
    label: "Overall index of social cohesion relative to income inequality"
  },
  {
    value: "HDI-Gender Inequality",
    label: "Overall index of social cohesion relative to gender inequality"
  },
  {
    value: "Polity index",
    label: "Overall index of social cohesion relative to polity index"
  },
  {
    value: "KOF Globalization index",
    label: "Overall index of social cohesion relative globalization"
  },
  {
    value: "Ethnic Fractionalization",
    label:
      "Overall index of social cohesion relative to ethnic fractionalization"
  },
  {
    value: "Language Fractionalization",
    label:
      "Overall index of social cohesion relative to language fractionalization"
  },
  {
    value: "Net Migration",
    label: "Net migration relative to the overall index of social cohesion"
  },
  {
    value: "Collective Individualism",
    label:
      "Overall index of social cohesion relative to collectivism-individualism"
  },
  {
    value: "Evaluation Life-today",
    label:
      "Life satisfaction (present) relative to overall index of social cohesion"
  }
];
const indicatorSeparatorStyle = {
  alignSelf: "stretch",
  backgroundColor: "#fff",
  marginBottom: 8,
  marginTop: 8,
  width: 1
};

const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #002F81",
    borderRadius: "0"
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    backgroundColor: "#002F81"
  })
};
const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSortDown} size="lg" />
    </components.DropdownIndicator>
  );
};

class ScatterplotChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: [
        {
          value: "GDP per capita PPP",
          label:
            "Overall index of social cohesion relative to gross domestic product"
        }
      ],
      value: "GDP per capita PPP",
      countryName: ""
    };
    this.onclick1 = this.onclick1.bind(this);
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption: [selectedOption] });
    console.log(`Option selected:`, selectedOption);
  };

  onclick1(e) {
    this.setState({
      value: e.target.value
    });
  }

  componentWillMount() {
    let countryName = this.props.countryName;

    console.log("will mount wrapper", countryName);
    this.setState({
      ...this.state,
      countryName
    });
  }

  render() {
    const { selectedOption, countryName } = this.state;
    return (
      <section id="determinants" class="section_token token_sale overflow_hide">
        <div class="row">
          <div class=" col-md-12 col-sm-12">
            <div class="title_dark title_border text-center">
              <h2>Determinants and Outcomes </h2>
            </div>
          </div>
        </div>
        <div className="row layout no-gutters">
          <div className="col-lg-7 col-md-6  col-md-8 col-md-12 col-sm-12 Indicators">
            <Select
              options={options}
              name="Sentiments"
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Indicators"
              components={{ DropdownIndicator, IndicatorSeparator }}
              value={selectedOption}
              styles={customStyles}
              onChange={this.handleChange}
            />
            <br />
            <div className="scatterplot">
              <div className="scatterplot title" style={{ marginLeft: "40%" }}>
                {" "}
                <p>{data[selectedOption[0].value]["title"]}</p>
              </div>
              <Scatter
                value={selectedOption[0].value}
                Indicators={selectedOption[0].value}
                countryName={countryName}
              />
            </div>
          </div>
          <div
            className="col-lg-5 col-md-12 col-sm-12  explanationDiv"
            style={{ textAlign: "initial", textTransform: "none" }}
          >
            <div className="explanation">
              <h2 class=" h2Bold"> {selectedOption[0].label}</h2>
              <p> {data[selectedOption[0].value]["explained"]} </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ScatterplotChart;
