import React, { Component } from 'react';
import Select, { components } from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ContinuousColorLegend } from "react-vis";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import './ComponentWrapper.css';
// import './App.css';/
import WorldMap from './AsiaMap';

const options = [
  { value: 'Overallindex', label: 'Overall Index' },
  { value: 'Socialnetwork', label: 'Social Networks' },
  { value: 'Trustinpeople', label: 'Trust in People' },
  { value: 'Acceptanceofdiversity', label: 'Acceptance of Diversity' },
  { value: 'Identification', label: 'Identification' },
  { value: 'TrustinInstitutions', label: 'Trust in Institutions' },
  { value: 'PerceptionOfFairness', label: 'Perception of Fairness' },
  { value: 'SolidarityAndHelpfulness', label: 'Solidarity and Helpfulness' },
  { value: 'RespectForSocialrules', label: 'Respect for Social Rules' },
  { value: 'CivicParticipation', label: 'Civic Participation' },
];
const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #002F81",
    borderRadius: '0',
    Zindex: '10000'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    backgroundColor: '#002F81',
  })
}
const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: "#fff",
  marginBottom: 8,
  marginTop: 8,
  width: 1,
};
const IndicatorSeparator = ({ innerProps }) => {
  return <span style={indicatorSeparatorStyle} {...innerProps} />;
};

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faSortDown} size="lg" />
    </components.DropdownIndicator>
  );
};
const options1 = [
  { value: '2004-08', label: '2004-08' },
  { value: '2009-15', label: '2009-15' },
]
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption1: [{ value: '2004-08', label: '2004-08' }],
      selectedOption: [{ value: 'Overallindex', label: 'Overall index' }],
      render: false
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption: [selectedOption] });
    console.log(`Option selected:`, selectedOption);
  }
  handleChange1 = (selectedOption1) => {
    this.setState({ selectedOption1: [selectedOption1] });
    console.log(`Option selected:`, selectedOption1);
  }


  componentDidMount() {
    setTimeout(function () { //Start the timer
      this.setState({ render: true }) //After 1 second, set render to true
    }.bind(this), 3000)
  }

  render() {
    const { selectedOption } = this.state;
    const { selectedOption1 } = this.state;
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName('body')[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;


    if (x > 810) {
      return (
        <section id="token" class="section_token" style={{ height: "770px", paddingTop: '5%', backgroundColor: "#F1F1F1" }}>
          <div class="container" style={{ display: "flex" }}>
            <div className="row col-sm-6 inidicatorSelect">
              <div className="ColorLegend">
                <div clasName="Legend" style={{ "display": "flex", "paddingTop": "5%" }}>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#004481" }}></div>
                    <p>Top-Tier</p>
                  </div>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#77aec7" }}></div>
                    <p>Mid-Top</p>
                  </div>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#c7d8e1" }}></div>
                    <p>Mid</p>
                  </div>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#d8b643" }}></div>
                    <p>Mid-Bottom</p>
                  </div>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#e26a09" }}></div>
                    <p>Bottom</p>
                  </div>
                </div>
              </div>
              <WorldMap Time={selectedOption1[0].value} Indicators={selectedOption[0].value} />

            </div>
            <div className="col-md-6 inidicatorSelect" >
              <div class="col-md-12">
                <div class="title_blue_dark" >
                  <h4 style={{ color: "#424242" }}>Asia Map Social Cohesion</h4>

                </div>
              </div>

              <div class="col-sm-12 inidicatorSelect" style={{ display: "flex" }}>
                <div className="col-sm-12 col-md-6 inidicatorSelect">
                  Choose Year
                    <br />

                  <Select
                    options={options1}
                    name="Sentiments"
                    components={{ DropdownIndicator, IndicatorSeparator }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Indicators"
                    value={selectedOption1}
                    styles={customStyles}
                    onChange={value => this.handleChange1(value)}
                  />

                </div>
                <div className="col-sm-12 col-md-6 inidicatorSelect">
                  Choose Indicators
                    <br />
                  <Select
                    options={options}
                    name="Sentiments"
                    components={{ DropdownIndicator, IndicatorSeparator }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Indicators"
                    value={selectedOption}
                    styles={customStyles}
                    onChange={value => this.handleChange(value)}
                  />
                </div>
              </div>
              <div class="token_rtinfo bg-white border_right">
                <div class="row text-center" style={{ paddingTop: "20%" }}>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="token_rt_value">
                      <p> The map displays the country
scores for the overall index as well as for the nine
dimensions in each of the
examined time periods. The
five colors designate the top
tier (dark blue), second tier
(blue), middle tier (light blue),
fourth tier (yellow), and bottom
    tier (orange)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    else if (x < 790 && x > 560) {
      return (
        <section id="token" class="section_token" style={{ height: "780px", backgroundColor: "rgba(208, 207, 205, 0.13)" }}>
          <div class="container">
            <div class="row ">
              <div class=" col-md-12">
                <div class="title_blue_dark ">
                  <h4 style={{ color: "#424242" }}>Asia Map Social Cohesion      </h4>

                </div>
              </div>
            </div>
          </div>
          <div class="container" >
            <div className="col-sm-12 inidicatorSelect">
              <div className="col-sm-12 col-md-6 inidicatorSelect">
                Choose Year
                  <br />
                {/* <div className="row">
                    <div className="col-sm-12"> */}
                <Select
                  options={options1}
                  name="Sentiments"
                  components={{ DropdownIndicator, IndicatorSeparator }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Indicators"
                  value={selectedOption1}
                  styles={customStyles}
                  onChange={value => this.handleChange1(value)}
                />
                {/* </div> */}
                {/* </div> */}
              </div>
              <div className="col-sm-12 col-md-6 inidicatorSelect">
                Choose Indicators
                  <br />
                <Select
                  options={options}
                  name="Sentiments"
                  components={{ DropdownIndicator, IndicatorSeparator }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Indicators"
                  value={selectedOption}
                  styles={customStyles}
                  onChange={value => this.handleChange(value)}
                />
              </div>
            </div>

            <div class="col-sm-12 col-md-8">
              <div class="container">
                <div class="col-sm-12 col-md-8">
                  <div className="ColorLegend">
                    <div clasName="Legend" style={{ "display": "flex", "paddingTop": "5%" }}>
                      <div className="tier">
                        <div className="circle" style={{ "background-color": "#004481" }}></div>
                        <p>Top-Tier</p>
                      </div>
                      <div className="tier">
                        <div className="circle" style={{ "background-color": "#77aec7" }}></div>
                        <p>Mid-Top</p>
                      </div>
                      <div className="tier">
                        <div className="circle" style={{ "background-color": "#c7d8e1" }}></div>
                        <p>Mid</p>
                      </div>
                      <div className="tier">
                        <div className="circle" style={{ "background-color": "#d8b643" }}></div>
                        <p>Mid-Bottom</p>
                      </div>
                      <div className="tier">
                        <div className="circle" style={{ "background-color": "#e26a09" }}></div>
                        <p>Bottom</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <WorldMap Time={selectedOption1[0].value} Indicators={selectedOption[0].value} />
            </div>
          </div>



        </section>
      )

    }
    else {

      return (
        <section id="token" class="section_token" style={{ height: "auto", backgroundColor: "rgba(208, 207, 205, 0.13)" }}>
          <div class="container">
            <div class="row text-center">
              <div class="col-md-12">
                <div class="title_blue_dark text-center">
                  <h4 style={{ color: "#424242" }} >Asia Map Social Cohesion  </h4>

                </div>
              </div>
            </div>
          </div>



          {/* style={{display: "flex"}} */}
          <div class="container" >
            <div className="col-sm-12 inidicatorSelect" style={{ marginTop: "0%" }}>
              <div className="col-sm-12 inidicatorSelect">
                Choose Year
                  <br />
                {/* <div className="row"> */}
                {/* <div className="col-sm-12" style={{marginLeft:'-16px',width: '290px'}}> */}
                <Select
                  options={options1}
                  name="Sentiments"
                  components={{ DropdownIndicator, IndicatorSeparator }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Indicators"
                  value={selectedOption1}
                  styles={customStyles}
                  onChange={value => this.handleChange1(value)}
                  width="250px"
                />
                {/* </div> */}
                {/* </div> */}
              </div> <br />
              <div className="col-sm-12 inidicatorSelect">
                Choose Indicators
                  <br />
                <Select
                  options={options}
                  name="Sentiments"
                  components={{ DropdownIndicator, IndicatorSeparator }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Indicators"
                  value={selectedOption}
                  styles={customStyles}
                  onChange={value => this.handleChange(value)}
                />
              </div>
            </div>

            <div class="col-sm-12 col-md-8">
              {/* <div class="container"> */}
              {/* <div class="col-sm-12 col-md-8 "> */}
              <div className="ColorLegend">
                <div clasName="Legend" style={{ "display": "flex", "flexWrap": 'wrap', "paddingTop": "5%" }}>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#004481" }}></div>
                    <p>Top-Tier</p>
                  </div>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#77aec7" }}></div>
                    <p>Mid-Top</p>
                  </div>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#c7d8e1" }}></div>
                    <p>Mid</p>
                  </div>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#d8b643" }}></div>
                    <p>Mid-Bottom</p>
                  </div>
                  <div className="tier">
                    <div className="circle" style={{ "background-color": "#e26a09" }}></div>
                    <p>Bottom</p>
                  </div>
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
              <WorldMap Time={selectedOption1[0].value} Indicators={selectedOption[0].value} />

              <div class="token_rtinfo bg-white border_right">
                <div class="row text-center" style={{ marginTop: '-16px' }}>
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="token_rt_value">
                      <p> The map displays the country
scores for the overall index as well as for the nine
dimensions in each of the
examined time periods. The
five colors designate the top
tier (dark blue), second tier
(blue), middle tier (light blue),
fourth tier (yellow), and bottom
    tier (orange)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </section>
      )
    }
  }
}

export default App;
