import React from "react";

export default class BreakSection extends React.Component {
  state = {
    data: {}
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.data) {
      this.setState({
        ...this.state,
        data: nextProps.data
      });
    }
  }

  render() {
    console.log("point section", this.state.data[0]);

    const { data } = this.state;

    return (
      <section
        id="brief"
        class=" overflow_hide"
        style={{ background: "#E4ECF1" }}
      >
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div class="title_default_light">
              <h2 class=" h2Bold">Brief Description </h2>
              <p>{data.countryShortDesc}</p>
              <p>{data.countryBrief}</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
