import { KEY_FEAGURE } from "../type.js";

// import api from './../api/key_feagure_api';
import axios from "axios";

export function setKeyfeagure(data) {
  return {
    type: KEY_FEAGURE,
    data
  };
}

export const getAllKeyfeagure = async (data, dispatch) => {
  console.log("getAllKeyfeagure", data);
  // const res = await axios.post(`http://68.183.90.78:4001/api/cohesion/${data}`);
  const res = await axios.post(`/api/cohesion/${data}`);
  // console.log("resssssssssssssssssssss",res.data.message[0]);
  try {
    // console.log("------------------DAta---------------",res.data.message[0]);
    return res.data.message[0];
    // return dispatch({ type: KEY_FEAGURE, data: res.data.message[0] });
  } catch (err) {
    return err;
  }
};
