import React, { Component } from "react";
import "../../../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  LabelSeries,
  LineSeries,
  MarkSeries,
  VerticalGridLines,
  DiscreteColorLegend,
  HorizontalGridLines,
  XAxis,
  YAxis,
  Hint
} from "react-vis";
import data from "./Data/data.json";
// import './ScatterPlot.css';

const ITEMS = [
  {
    title: "Simple Linear",
    color: "#45aeb1",
    strokeStyle: "dashed",
    strokeDasharray: "2 4"
  },
  {
    title: "Linear adjusted for GDP",
    color: "#f93",
    strokeDasharray: "1 2 3 4 5 6 7"
  }
];

let ITEMS2 = [
  {
    title: "Simple Linear",
    color: "#000 ",
    strokeWidth: "90",
    width: "100",
    size: "20",
    strokeStyle: "dashed",
    strokeDasharray: "10 40"
  }
];
let ITEMS1;

let xTitle;
let yTitle = "Social Cohesion Index";

class Scatter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scatter: data["GDP per capita PPP"]["scatter"],
      line: data["GDP per capita PPP"]["line"],
      line2: data["GDP per capita PPP"]["line2"],
      value: false
    };
  }

  componentWillMount() {
    let incomingScatterData = data[this.props.value]["scatter"];
    let { countryName } = this.props;
    let newScatterData = [];
    this.changeData(incomingScatterData, countryName);
  }

  switchCountryShortform(countryShortName) {
    let countryShort = "";
    switch (countryShortName) {
      case "IN":
        return (countryShort = "India");
        break;
      case "CN":
        return (countryShort = "China");
        break;
      case "ID":
        return (countryShort = "Indonesia");
        break;
      case "NP":
        return (countryShort = "Nepal");
        break;
      case "KH":
        return (countryShort = "Cambodia");
        break;
      case "AF":
        return (countryShort = "Afghanistan");
        break;
      case "BT":
        return (countryShort = "Bhutan");
        break;
      case "BD":
        return (countryShort = "Bangladesh");
        break;
      case "HK":
        return (countryShort = "Hong Kong");
        break;
      case "SG":
        return (countryShort = "Singapore");
        break;
      case "TH":
        return (countryShort = "Thailand");
        break;
      case "TW":
        return (countryShort = "Taiwan");
        break;
      case "LK":
        return (countryShort = "Sri Lanka");
        break;
      case "JP":
        return (countryShort = "Japan");
        break;
      case "LA":
        return (countryShort = "Laos");
        break;
      case "MY":
        return (countryShort = "Malaysia");
        break;
      case "PH":
        return (countryShort = "Philippines");
        break;
      case "VN":
        return (countryShort = "Vietnam");
        break;
      case "MM":
        return (countryShort = "Myanmar");
        break;
      case "KR":
        return (countryShort = "South Korea");
        break;
      case "PK":
        return (countryShort = "Pakistan");
        break;
      case "MN":
        return (countryShort = "Mongolia");
        break;
      default:
    }
  }

  changeData(incomingData, country) {
    let newScatterData = [];

    let countryShort = "";

    switch (country) {
      case "India":
        countryShort = "IN";
        break;
      case "China":
        countryShort = "CN";
        break;
      case "Indonesia":
        countryShort = "ID";
        break;
      case "Nepal":
        countryShort = "NP";
        break;
      case "Cambodia":
        countryShort = "KH";
        break;
      case "Afghanistan":
        countryShort = "AF";
        break;
      case "Bhutan":
        countryShort = "BT";
        break;
      case "Bangladesh":
        countryShort = "BD";
        break;
      case "Hong Kong":
        countryShort = "HK";
        break;
      case "Singapore":
        countryShort = "SG";
        break;
      case "Thailand":
        countryShort = "TH";
        break;
      case "Taiwan":
        countryShort = "TW";
        break;
      case "Sri Lanka":
        countryShort = "LK";
        break;
      case "Japan":
        countryShort = "JP";
        break;
      case "Laos":
        countryShort = "LA";
        break;
      case "Malaysia":
        countryShort = "MY";
        break;
      case "Philippines":
        countryShort = "PH";
        break;
      case "Vietnam":
        countryShort = "VT";
        break;
      case "Myanmar":
        countryShort = "MM";
        break;
      case "South Korea":
        countryShort = "KR";
        break;
      case "Pakistan":
        countryShort = "PK";
        break;
      case "Mongolia":
        countryShort = "MN";
        break;
      default:
    }
    incomingData.map(d => {
      if (d.label === countryShort) {
        d["color"] = 0;
      } else {
        d["color"] = 100;
      }
      newScatterData.push(d);
    });

    return incomingData;
  }

  componentWillReceiveProps(nextProps) {
    let incomingScatterData = data[nextProps.value]["scatter"];

    console.log("Will receive", incomingScatterData);
    let scatterData = this.changeData(
      incomingScatterData,
      this.props.countryName
    );
    this.setState({
      scatter: scatterData,
      line: data[nextProps.value]["line"],
      line2: data[nextProps.value]["line2"]
    });
  }

  render() {
    // xTitle = this.props.value;
    var w = window,
      d = document,
      e = d.documentElement,
      g = d.getElementsByTagName("body")[0],
      x = w.innerWidth || e.clientWidth || g.clientWidth,
      y = w.innerHeight || e.clientHeight || g.clientHeight;

    let Xwidth, Xheight, cRadius;
    if (x > 1100) {
      Xwidth = 700;
      Xheight = 400;
      cRadius = "5";
    } else if (x < 1090 && x > 710) {
      // Xwidth=600;
      // Xheight=400;
      // cRadius='3'
      Xwidth = 450;
      Xheight = 450;
      cRadius = "3";
    } else {
      Xwidth = 300;
      Xheight = 300;
      cRadius = "3";
    }

    if (
      this.props.value === "Polity index" ||
      this.props.value === "Net Migration" ||
      this.props.value === "Evaluation Life-today"
    ) {
      if (this.props.value === "Net Migration") {
        xTitle = "Social Cohesion Index  ('04 - '08) ";
        yTitle = this.props.value + " ('10)";
      } else if (this.props.value === "Polity index") {
        xTitle = "Social Cohesion Index  ('04 - '08) ";
        yTitle =
          "autocracy\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 " +
          this.props.value +
          "  ('09  -  '15) \xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0 democracy";
      } else {
        xTitle = "Social Cohesion Index  ('04 - '08) ";
        yTitle = this.props.value + "  ('09  -  '15)";
      }
    } else if (
      this.props.value === "Ethnic Fractionalization" ||
      this.props.value === "Language Fractionalization"
    ) {
      xTitle = this.props.value + " (till '03)";
      yTitle = "Social Cohesion Index  ('09  -  '15)";
    } else if (this.props.value === "Collective Individualism") {
      xTitle = this.props.value;
      yTitle = "Social Cohesion Index  ('09  -  '15)";
    } else {
      xTitle = this.props.value + " ('04 - '08)";
      yTitle = "Social Cohesion Index  ('09  -  '15)";
    }

    if (
      this.props.value === "HDI-Gender Inequality" ||
      this.props.value === "KOF Globalization index" ||
      this.props.value === "Polity index" ||
      this.props.value === "Evaluation Life-today" ||
      this.props.value === "Net Migration"
    ) {
      if (this.props.value === "Net Migration") {
        ITEMS1 = [];
      } else {
        ITEMS1 = ITEMS;
      }
    } else {
      if (
        this.props.value === "Net-Income inequality Gini" ||
        this.props.value === "Ethnic Fractionalization" ||
        this.props.value === "Language Fractionalization" ||
        this.props.value === "Collective Individualism"
      ) {
        ITEMS1 = [];
      } else {
        ITEMS1 = ITEMS2;
      }
    }

    return (
      <div className="app">
        <XYPlot
          height={Xheight}
          width={Xwidth}
          onMouseLeave={() => this.setState({ value: false })}
        >
          <VerticalGridLines />
          <HorizontalGridLines />
          {this.props.value === "GDP per capita PPP" ? (
            <XAxis
              title={xTitle}
              tickFormat={v => {
                let array = [
                  "$300",
                  "",
                  "",
                  "",
                  "",
                  "$3000",
                  "",
                  "",
                  "",
                  "",
                  "$37000"
                ];
                return array[v / 0.5 - 11];
              }}
              position="middle"
              style={{
                text: {
                  stroke: "none",
                  fill: "#6b6b76",
                  fontWeight: 600,
                  textAnchor: "end"
                },
                title: { fontSize: 15 }
              }}
            />
          ) : (
            <XAxis
              title={xTitle}
              position="middle"
              style={{
                text: { stroke: "none", fill: "#6b6b76", fontWeight: 600 },
                title: { fontSize: 15 }
              }}
            />
          )}
          {/* <XAxis  title={xTitle}  tickFormat={v => {  let array = ['$300','','','','$3000','','','','','','$37000']; return array[v/0.5 - 11];}} position = "middle" style = {{text: {stroke: 'none', fill: '#6b6b76', fontWeight: 600},title:{fontSize:15}}}/> */}
          <YAxis
            title={yTitle}
            position="middle"
            style={{
              text: { stroke: "none", fill: "#6b6b76", fontWeight: 100 },
              title: { fontSize: 15 }
            }}
          />
          <MarkSeries
            data={this.state.scatter}
            size={cRadius}
            animation
            onValueMouseOver={datapoint => {
              this.setState({ value: datapoint });
            }}
            onMouseLeave={() => this.setState({ value: false })}
            // color = "blue"
          />
          <LabelSeries data={this.state.scatter} />
          <LineSeries
            style={{ strokeDasharray: "2  2" }}
            className="second-series"
            data={this.state.line}
            curve={"curveMonotoneX"}
          ></LineSeries>
          <LineSeries
            style={{ strokeDasharray: "4  4" }}
            className="second-series"
            data={this.state.line2}
            curve={"curveMonotoneX"}
          ></LineSeries>
          {this.state.value ? (
            <Hint
              value={this.state.value}
              align={{ vertical: "top", horizontal: "left" }}
              style={{
                backgroundColor: "#EFF0D5",
                fontColor: "red",
                width: "auto",
                height: "auto",
                padding: "4px",
                align: "center",
                boxShadow: "2px 1px 1px"
              }}
            >
              <p>{this.switchCountryShortform(this.state.value.label)}</p>
            </Hint>
          ) : null}
        </XYPlot>
        <DiscreteColorLegend
          items={ITEMS1}
          style={{ display: "inline-block", marginLeft: "5%" }}
          orientation="horizontal"
        />
      </div>
    );
  }
}

export default Scatter;
