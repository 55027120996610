import React, { Component } from "react"
import { geoMercator, geoPath } from "d3-geo"
import { feature } from "topojson-client";
import * as d3 from 'd3';
import worlddata from './Data/asiaTopo.json';
import Asia from './Data/dataAsia.json';
import { Popover, Button } from 'antd';
import 'antd/dist/antd.css';
class WorldMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      worldData: [],
      countries : {},
      citiesP:[
        // { name: "Hong Kong", coordinates: [101,23.5726], population: 14667000 },
        // { name: "Singapore", coordinates: [140.97576600000002,3.210484], population: 14667000 },
        { name: "Kolkata", coordinates: [400, 35], population: 14667000 }
      ],
      cities: [
        { name: "Kolkata", coordinates: [61,35.5726], population: 14667000 },
        { name: "Kolkata", coordinates: [62,36], population: 14667000 },
        { name: "Kolkata", coordinates: [64,36.1726], population: 14667000 },
        { name: "Kolkata", coordinates: [64.80,36.6726], population: 14667000 },
        { name: "Kolkata", coordinates: [63,37.5726], population: 14667000 },
        { name: "Kolkata", coordinates: [63.800,36.9726], population: 14667000 },
        { name: "Kolkata", coordinates: [61.00,37.8726], population: 14667000 },
        { name: "Kolkata", coordinates: [59.500,37], population: 14667000 },
        { name: "Kolkata", coordinates: [59.50,35.500], population: 14667000 },
        { name: "Kolkata", coordinates: [59.500,34.500], population: 14667000 },
        { name: "Kolkata", coordinates: [61.00,34], population: 14667000 }, 
      ]
}
}

  projection() {
    var w=window,
    d=document,
    e=d.documentElement,
    g=d.getElementsByTagName('body')[0],
    x=w.innerWidth || e.clientWidth || g.clientWidth,
    y=w.innerHeight || e.clientHeight || g.clientHeight;

    console.log("x----"+x+"y----"+y)
     if(x>811){
       return geoMercator()
       .scale(400)
       .translate([ -500 / 2, 1000 / 2 ]);
     } else{
       return geoMercator()
     }
  }
   scale = d3.scaleLinear()
            .domain([-3,3])
            .range(['#003082', '#d9ecf7']);
            componentDidCatch(error, info) {
              console.log("componentDidCatch",this.state.hasError)
              this.setState({ hasError: true });
            }

  componentDidMount() {
    this.setState({
     worldData: feature(worlddata, worlddata.objects.asialates).features,
     countries: Asia[this.props.Indicators]
   })
  }
  onMouseOverCountry = (data) => {
    console.log(data+"hello its working on every country");
  }
componentWillReceiveProps(nexProps){
  this.setState({
    countries: Asia[nexProps.Indicators]
  })
}
changeColor(index){
  if(index >= 0.8){
    return "#004481";
  }else if(index < 0.8 && index >= 0.2){
    return "#77aec7";
  } else if(index < 0.2 && index >= -0.2){
    return "#c7d8e1";
  } else if(index < -0.2 && index >=-0.85){
    return "#d8b643";
  } else if ( index < -0.85 ) {
    return "#e26a09"
  }
}
  render() {
     if (this.state.countries[this.props.Time]==undefined || null) {
      return <h1>Something went wrong.</h1>;
    }

     var w=window,
        d=document,
        e=d.documentElement,
        g=d.getElementsByTagName('body')[0],
        x=e.innerWidth ||d.clientWidth||g.clientWidth,
        y =e.innerHeight ||d.clientHeight ||g.clientHeight;

   if(x>1024){
    return (
    <div> 
      <svg width={600 } height={600 }>
        <g className="countries" transform="translate(-140,-10)">
          {
            this.state.worldData.map((d,i) => (
              <Popover content={this.state.countries[this.props.Time][d.properties.name]} style={{marginBottom: '0px'}} title={d.properties.name} trigger="hover">
              <path
                key={ `path-${ i }` }
                d={ geoPath().projection(this.projection())(d) }
                className="country"
                fill={ this.changeColor(this.state.countries[this.props.Time][d.properties.name]) }
                className="country"
                stroke="white"
                strokeWidth={ 0.5 }
                style={{stroke: 'whtie', strokeWidth: 0.5, strokeLinecap: 'round', strokeLinejoin: 'miter', strokeMiterlimit: 4, strokeOpacity: 2, strokeDasharray:'none'}}
              />
            </Popover>
            ))
          }

        </g>
        {/* <Popover
											content={"Data not available"}
											style={{ marginBottom: "0px" }}
											title={"North Korea"}
											trigger="hover"
										>
						<g className="markers" transform="translate(498,192)"> 
							{this.state.citiesP.map((city, i) => (
                
								<circle
									r={6}
									fill="gray"
									className="marker"
								/>
							))}
						</g>
						</Popover> */}

        {/* <g className="markers">
          <circle
            cx={ this.projection()([8,48])[0] }
            cy={ this.projection()([8,48])[1] }
            r={ 10 }
            fill="#E91E63"
            className="marker"
          />
        </g> */}

      
      </svg>
      </div>
    )
  }
  else if(x<1000 && x >510){
      return (
      <div> 
        <svg width={500 } height={400 } style={{marginLeft:"0%"}}>
          <g className="countries" transform="translate(-450,-10)">
            {
              this.state.worldData.map((d,i) => (
               <Popover content={this.state.countries[this.props.Time][d.properties.name]} style={{marginBottom: '0px'}} title={d.properties.name} trigger="hover">
                <path
                  key={ `path-${ i }` }
                  d={ geoPath().projection(this.projection())(d) }
                  className="country"
                  fill={ this.changeColor(this.state.countries[this.props.Time][d.properties.name]) }
                  className="country"
                  stroke="white"
                  strokeWidth={ 0.5 }
                  style={{stroke: 'whtie', strokeWidth: 0.5, strokeLinecap: 'round', strokeLinejoin: 'miter', strokeMiterlimit: 4, strokeOpacity: 2, strokeDasharray:'none'}}
                />
              </Popover>
              ))
            }
  
          </g>
          {/* <Popover
											content={"Data not available"}
											style={{ marginBottom: "0px" }}
											title={"North Korea"}
											trigger="hover"
										>
						<g className="markers" transform="translate(488,194)"> 
							{this.state.citiesP.map((city, i) => (
                
								<circle
									r={5}
									fill="gray"
									className="marker"
								/>
							))}
						</g>
						</Popover> */}
          <g className="markers">
          <circle
            cx={ this.projection()([8,48])[0] }
            cy={ this.projection()([8,48])[1] }
            r={ 50 }
            fill="#E91E63"
            className="marker"
          />
        </g>
  
      
        </svg>
        </div>
      )}

      else {
        return (
        <div> 
         
          <svg width={280 } height={320 }  viewBox="0 0 250 240">
            <g className="countries" transform="translate(-640,-60)">
              {
                this.state.worldData.map((d,i) => (
                 <Popover content={this.state.countries[this.props.Time][d.properties.name]} style={{marginBottom: '0px'}} title={d.properties.name} trigger="hover">
                  <path
                    key={ `path-${ i }` }
                    d={ geoPath().projection(this.projection())(d) }
                    className="country"
                    fill={ this.changeColor(this.state.countries[this.props.Time][d.properties.name]) }
                    className="country"
                    stroke="white"
                    strokeWidth={ 0.5 }
                    style={{stroke: 'whtie', strokeWidth: 0.5, strokeLinecap: 'round', strokeLinejoin: 'miter', strokeMiterlimit: 4, strokeOpacity: 2, strokeDasharray:'none'}}
                  />
                </Popover>
                ))
              }

            </g>
            {/* <Popover
											content={"Data not available"}
											style={{ marginBottom: "0px" }}
											title={"North Korea"}
											trigger="hover"
										>
						<g className="markers" transform="translate(182,75)"> 
							{this.state.citiesP.map((city, i) => (
                
								<circle
									r={3}
									fill="gray"
									className="marker"
								/>
							))}
						</g>
						</Popover> */}
            <g className="markers">
          <circle
            cx={ this.projection()([8,48])[0] }
            cy={ this.projection()([8,48])[1] }
            r={ 50 }
            fill="#E91E63"
            className="marker"
          />
        </g>
          </svg>
          </div>
        )}


  }
}

export default WorldMap;

