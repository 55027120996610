import React,{Component} from 'react'
import MenuCountry from './../MenuCountry/menu'
import CountryHeaderSection from './../CountryHeaderPage/countryHeader'
import KeyFeagure from './../KeyFeagure/keyFeagure'
import ScatterplotChart from './../scatterplot/ComponentWrapper'
import { getAllKeyfeagure} from './../../action/action_keyfeagure'
import {connect} from 'react-redux'
import BreakSection from './../BreakSection/breakSection'
import ComparativeAnalysis from './../ComparativeAnalysis/ComparativeAnalysis'
 class CountryTemplate extends Component{

    state = {
        countryDetails:{}
    }
    componentWillMount(){
        let countryName = this.props.location.pathname.split("/")[1];
        getAllKeyfeagure(countryName)
            .then((data) => {                
                let data2 ='';
                data2 =data;
                this.setState({
                    ...this.state,
                    countryDetails: data
                })
            })
        }



    render(){
        const { countryDetails } = this.state;
 
        if (countryDetails.length > 0) {
            // console.log("***********222*********** 222",countryDetails.keyFigures)
          }
let countryName = this.props.location.pathname.split("/")[1];


        return(
            <div>    
                <MenuCountry /> 
                <CountryHeaderSection countryName={countryName} data={this.state.countryDetails}/>
                <KeyFeagure  data={this.state.countryDetails.keyFigures}/>
                <div className = 'templateDiv'>
                    <ComparativeAnalysis countryName={countryName} data={this.state.countryDetails}/>
                    <BreakSection  data={this.state.countryDetails}/>
                    <ScatterplotChart countryName={countryName}/>  
                </div>
            </div>
        )
    }
}
export default connect(null, {getAllKeyfeagure})(CountryTemplate);