import React from 'react'
import AnyChart from 'anychart-react'
import anychart from 'anychart'
import sunburtsDesc_data from './data/sunburstData.json'
import './sunburst.css';

let stage = anychart.graphics.create();

var data = [
    {
        name: "Cohesion", 
        children: [
            {   
                name: "Social relations",
                children: [
                    {name: "Social Networks"},
                    {name: "Trust in People"},
                    {name: "Acceptance of Diversity"},
                ]
               
            },
            {
                name: "Connectedness", 
                children: [
                    {name: "Identification"},
                    {name: "Trust in Institutions"},
                    {name: "Perception of Fairness"},
                ]
            },
            {   
                name: "Focus on the Common Good",
                children: [
                    {name: "Civic Participation"},
                    {name: "Respect for Social Rules"},
                    {name: "Solidarity and Helpfulness"}
                ]
            }
        ]
    }
];
 

class Sunburst extends React.Component {
 
     constructor(props){
         super(props)
         this.state = {
            sunburstDesciption:'  Social cohesion is understood as the quality of social cooperation and togetherness in a territorially delimited community. The Social Cohesion Radar (SCR) offers an empirical, international comparison of social cohesion in various countries worldwide. The SCR is based on a broad set of indicators drawn from comparative international surveys and other data sources. It breaks down the concept of social cohesion into three domains — social relations, connectedness and focus on the common good. Each of these domains comprises three measurable dimensions: social networks, trust in people, acceptance of diversity, identification, trust in institutions, perception of fairness, solidarity and helpfulness, respect for social rules, and civic participation.',
            label:'Cohesion',
            pointBrief:'Social relations',
            pointBrief1:'Connectedness',
            pointBrief2:'Focus on the common good'
        };

     }

  


    func_listen = (data) =>{
        try {
        let drillData=data.target.Gd.name; 
        let briefData,pointBrief,pointBrief1,pointBrief2; 
        if(data.actualTarget.im.currentmaxdepth==1){
          return
        }
        else{

        if(drillData=="Acceptance of Diversity"){
          briefData =sunburtsDesc_data.Social_relations.brief;
          pointBrief=sunburtsDesc_data.Social_relations.Acceptance_Of_Diversity
        } else if(drillData=="Social Networks"){
            briefData =sunburtsDesc_data.Social_relations.brief;
            pointBrief=sunburtsDesc_data.Social_relations.Social_Networks
        }
        else if(drillData=="Trust in People"){
            briefData=sunburtsDesc_data.Social_relations.brief 
            pointBrief=sunburtsDesc_data.Social_relations.Trust_In_People
        }
        else if(drillData=="Perception of Fairness"){
            briefData =sunburtsDesc_data.Connectedness.brief
            pointBrief=sunburtsDesc_data.Connectedness.Identification

        }
        else if(drillData === "Trust in Institutions"){
            briefData=sunburtsDesc_data.Connectedness.brief 
            pointBrief=sunburtsDesc_data.Connectedness.Trust_In_Institutions
        }
        else if(drillData=="Identification"){
            briefData =sunburtsDesc_data.Connectedness.brief
            pointBrief=sunburtsDesc_data.Connectedness.Perception_Of_Fairness
        }
        else if(drillData=="Civic Participation"){
            briefData=sunburtsDesc_data.Focus_on_the_common_good.brief 
            pointBrief=sunburtsDesc_data.Focus_on_the_common_good.Civic_Participation
        }
        else if(drillData=="Respect for Social Rules"){
            briefData =sunburtsDesc_data.Focus_on_the_common_good.brief
            pointBrief=sunburtsDesc_data.Focus_on_the_common_good.Respect_For_Social_Rules
        }
        else if(drillData=="Solidarity and Helpfulness"){
            briefData=sunburtsDesc_data.Focus_on_the_common_good.brief 
            pointBrief=sunburtsDesc_data.Focus_on_the_common_good.Solidarity_and_helpfulness
        }
 
     this.setState({label:drillData,
         sunburstDesciption:briefData,
         pointBrief:pointBrief,
         pointBrief1:'',
         pointBrief2:''
    })
 
        }
} catch (error) {
    // ...
  }
    };

 
    render() {
              
        var w=window,
        d=document,
        e=d.documentElement,
        g=d.getElementsByTagName('body')[0],
        x=w.innerWidth || e.clientWidth || g.clientWidth,
        y=w.innerHeight || e.clientHeight || g.clientHeight;
      
        let stage = anychart.graphics.create();

        var data = [
            {
                name: "Cohesion", 
                children: [
                    {   
                        name: "Social relations",
                        children: [
                            {name: "Social Networks"},
                            {name: "Trust in People"},
                            {name: "Acceptance of Diversity"},
                        ]
                    },
                    {
                        name: "Connectedness", 
                        children: [
                            {name: "Identification"},
                            {name: "Trust in Institutions"},
                            {name: "Perception of Fairness"},
                        ]
                    },
                    {   
                        name: "Focus on the Common Good",
                        children: [
                            {name: "Civic Participation"},
                            {name: "Respect for Social Rules"},
                            {name: "Solidarity and Helpfulness"}
                        ]
                    }
                ]
            }
        ];
        anychart.theme('lightBlue')
        anychart.licenseKey('bertelsmann-stiftung.de-8f96a26-79c6144e');
       
        var chart = anychart.sunburst(data, "as-tree");
        chart.level(2).labels().position('circular');
        chart.level(2).labels().minFontSize(11)
        chart.level(2).labels().maxFontSize(20)
        chart.level(2).labels().hAlign('center')
        chart.level().labels().adjustFontSize(true, true)
        chart.container("container");
        // chart.background().fill({
        //     keys: ["#fff", "#66f", "#fff"]
        //   });
        chart.background().fill("#E2ECF2 0.5");
        chart.palette(['#07447f', '#1383b0', '#7bafc7', '#339c99']);
        chart.listen('pointClick',this.func_listen, function (e) {
            if (e.point.get('drillChange')) {
              chart.getSeries(0).data(e.point.get('drillChange'));
            }
            else {
          
            }
          });
          chart.credits().enabled(false);
          chart.draw();


          if(x>810){
        return (
            <section id="about" >
            {/* style={{backgroundColor:'#E4ECF1'}} */}
            <div className="container">
                <div className="row">
                <div className=" col-xl-6 col-sm-12">
            	<div className="svg-text-center res_md_mb_30 res_sm_mb_20">
                <AnyChart
                    theme = "lightBlue"
                    instance={stage}
                    width={600}
                    height={600}
                    charts={[chart]}
                    // charts={this.chart.listen([(e)=>this.eventClick])}
                />
                </div>
            </div>
            <div className="col-xl-6 col-sm-12" >
                <div className="title_blue_dark">
                  <h4 style = {{textAlign : 'left'}}>Social Cohesion Radar  </h4>
                  <p>
                  
                  
                  {this.state.sunburstDesciption}
                  <br/>
                  
                  </p>
                  
                </div>
            </div>
 </div>
    </div>
</section>
        )
    }
    else{

        return (
            <section id="about" >
            {/* style={{backgroundColor:'#E4ECF1'}} */}
            <div className="container">
                <div className="row">
                <div className="col-xl-6 col-sm-12">
            	<div className="svg-text-center res_md_mb_30 res_sm_mb_20">
                <AnyChart
                    instance={stage}
                    width={300}
                    height={300}
                    charts={[chart]}
                    // charts={this.chart.listen([(e)=>this.eventClick])}
                />
 </div>
            </div>
<div className="col-xl-6 col-sm-12 text_md_center">
                <div className="title_blue_dark">
                  <h4 style = {{textAlign : 'left'}}>Social Cohesion Radar  </h4>
                  <p>
                  
                  
                  {this.state.sunburstDesciption}
                  <br/>
                  {/* <h4>{this.state.label} </h4> */}
                  <h5></h5>
                  <h5> {this.state.label} </h5> 
                  {this.state.pointBrief}
                  <h5></h5>
                  {this.state.pointBrief1}
                  <h5></h5>
                  {this.state.pointBrief2}
 
                  </p>
                  
                </div>
            </div>
 </div>
    </div>
</section>
        )


    }
} 


}

export default Sunburst;